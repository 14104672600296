import { Buffer } from 'buffer';
import jschardet from 'jschardet';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useTargetListConfig from './UploadTargetListForm.config';
import { supportedCSVEncodings } from 'basics/constants/common.constants';
import { CepStatus } from 'basics/enums/maya.enums';
import { useCreateTargetListFromCSVMutation } from 'basics/graphql/mutations/createTargetList';
import useGetCepByIdLazyQuery from 'basics/graphql/queries/getCepByIdLazy';
import { CepStateType } from 'basics/types/maya.types';
import { succesToast, warningToast, errorToast } from 'basics/utils/toast';
import { HandleFilesExternal } from 'components/QuickForm/QuickForm.types';
import { AssetTarget, AssetType, CustomerEngagementPlanning } from 'generated/maya.types';
import cepState from 'states/cep.states';

const useUploadTargetList = (
  updateTargetList: () => Promise<void>,
  setLoading: Dispatch<SetStateAction<boolean>>,
) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const [runCreateMutation, { loading: uploadLoading }] = useCreateTargetListFromCSVMutation();

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const isCepApproved = cepStateValue.cep?.status === CepStatus.APPROVED;

  const { queryGetCepById } = useGetCepByIdLazyQuery();
  const { uploadTargetListConfig } = useTargetListConfig();

  const detectNotSupportedEncoding = async (event: ProgressEvent<FileReader>) => {
    const fileBuffer = new Uint8Array(event.target?.result as ArrayBuffer);
    const buffer = Buffer.from(fileBuffer);
    const binaryString = buffer.toString('binary');
    const detected = jschardet.detect(binaryString);
    const detectedEncoding = detected.encoding?.toLowerCase();

    if (!supportedCSVEncodings.includes(detectedEncoding) && flags.mayaBdcfcsd1196ImplementAlertSystem) {
      warningToast(t('maya_encoding_warning'));
    }
  };

  const handleFilesExternal: HandleFilesExternal = async (files, setErrorMessage) => {
    const errorMessage = t('errors_standard');
    if (!cepStateValue.cep?._id || !cepStateValue.cep?.formattedCepId) {
      setErrorMessage(errorMessage);
      return;
    }
    const { size, name } = files[0];
    const reader = new FileReader();

    setLoading(true);

    reader.onload = async (event) => {
      try {
        await detectNotSupportedEncoding(event);

        const { data: targetList, error } = await runCreateMutation(
          files[0],
          {
            size,
            name,
            assetType: AssetType.csv,
            assetTarget: AssetTarget.targetListCsv,
          },
          String(cepStateValue.cep?._id),
          String(cepStateValue.cep?.formattedCepId),
        );

        cepState.targetList = [
          ...cepStateValue.targetList,
          ...(targetList && targetList.length ? targetList : []),
        ];

        const { data: { getCustomerEngagementPlanningById: customerEngagementPlanning } } = await queryGetCepById(
          { variables: { id: cepStateValue.cep?._id } },
        );

        if (cepState.cep) {
          cepState.cep.globalCepSyncStatus = (customerEngagementPlanning as CustomerEngagementPlanning).globalCepSyncStatus;
        }

        if (targetList && !error) {
          succesToast(t('maya_target_import_success', { number: targetList.length }));
        } else {
          errorToast(error || t('maya_target_import_error'));
        }

        await updateTargetList();
        setLoading(false);
      } catch (error) {
        errorToast(errorMessage);
      }
    };

    reader.onerror = () => {
      errorToast(errorMessage);
      setLoading(false);
    };

    reader.readAsArrayBuffer(files[0]);
  };

  return {
    uploadTargetListConfig,
    handleFilesExternal,
    data: Object.values(cepStateValue.targetList),
    uploadLoading,
    t,
    isCepApproved,
  };
};

export default useUploadTargetList;
